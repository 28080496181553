import bannerImage from '/assets/images/payment-methods-page-images/icon-bank.svg';
import LegalBanner from "../../components/privacy-policy-page-components/legal-banner/LegalBanner.tsx";
import {PaymentMethodsDemonstrationsCardsConfig} from "../../configs/payment-methods-page/paymentMethodsDemonstrationsCards.ts";
import DemonstrationsCards from "../../components/demonstrations/DemonstrationsCards.tsx";
import SinglePaymentMethodDescriptionSection
    from "../../components/single-payment-method-page-components/single-payment-method-description-section/SinglePaymentMethodDescriptionSection.tsx";
import {WireTransferDescriptionSectionConfig} from "../../configs/payment-methods-page/wireTransfersDescriptionSection.ts";
import GetStartedBanner from "../../components/home-page-components/get-started-banner/GetStartedBanner.tsx";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const WireTransfersPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.wire_transfer'} canonical={'routes.payment_methods_bank_transfer'} />
            <LegalBanner heading={'wire_transfers_service'} image={bannerImage} />
            <DemonstrationsCards config={PaymentMethodsDemonstrationsCardsConfig} />
            <SinglePaymentMethodDescriptionSection config={WireTransferDescriptionSectionConfig} />
            <GetStartedBanner bannerText={'payment_methods_banner_text'}
                              linkTo={'routes.pricing'}
                              linkClassname={'opy-button opy-button--white opy-button--medium text-center'}
                              linkText={'get_prices'} />
        </>
    )
}

export default WireTransfersPage;
