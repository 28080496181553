import LegalBanner from "../../components/privacy-policy-page-components/legal-banner/LegalBanner.tsx";
import bannerImage from "/assets/images/why-opay-page-images/icon-about-feature-13.svg";
import LegalPageSections
    from "../../components/privacy-policy-page-components/privacy-policy-sections/LegalPageSections.tsx";
import {ComplaintSubmissionSectionsConfig} from "../../configs/legal-pages/complaintSubmissionSections.ts";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const ComplaintSubmissionPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.complaint_submission'} canonical={'routes.complaint_submission'} />
            <LegalBanner heading={'submitting_a_complaint'} image={bannerImage} />
            <LegalPageSections config={ComplaintSubmissionSectionsConfig} />
        </>
    )
}

export default ComplaintSubmissionPage;
