import LegalBanner from "../../components/privacy-policy-page-components/legal-banner/LegalBanner.tsx";
import bannerImage from "/assets/images/why-opay-page-images/icon-about-feature-6.svg";
import GeneralTermsUpdatesSection
    from "../../components/general-terms-page-components/general-terms-updates-section/GeneralTermsUpdatesSection.tsx";
import {GeneralTermsUpdatesSectionConfig} from "../../configs/legal-pages/generalTermsUpdatesSection.ts";
import LegalPageSections
    from "../../components/privacy-policy-page-components/privacy-policy-sections/LegalPageSections.tsx";
import {GeneralTermsPageSectionsConfig} from "../../configs/legal-pages/generalTermsSections.ts";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const GeneralTermsPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.general_terms'} canonical={'routes.general_terms'} />
            <LegalBanner heading={'general_terms'} image={bannerImage} />
            <GeneralTermsUpdatesSection config={GeneralTermsUpdatesSectionConfig}/>
            <LegalPageSections config={GeneralTermsPageSectionsConfig} />
        </>
    )
}

export default GeneralTermsPage;
