import {Trans, useTranslation} from "react-i18next";

const LegalPageSectionListItemTransComponent = ({text, locale}: {text: string, locale?: string}) => {

    const {t} = locale ? useTranslation('translation', {lng: locale}) : useTranslation();

    return (
        <>
            <Trans t={t} components={{
                lrv: <a href="https://vdai.lrv.lt/lt/" target="_blank" />,
                opayFacebook: <a href="https://www.facebook.com/OPAY.LT" target="_blank" />,
                opayLinkedIn: <a href="https://www.linkedin.com/company/company-opay" target="_blank" />,
                facebookPrivacyPolicy: <a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0" />,
                linkedInPrivacyPolicy: <a href="https://www.linkedin.com/legal/privacy-policy" />,
                facebookCookiesLink:<a className="text-blue-dark"
                                       href="https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0" />,
                livechatCookiesLink: <a className="text-blue-dark"
                                        href="https://www.livechat.com/help/privacy-and-security/" />,
                privacyEmail: <a className='contact-by-email' href="mailto:privacy@opay.eu" />,
                contactEmail: <a className='contact-by-email' href="mailto:info@opay.eu" />,
                phone: <a href="tel:+37061155554" />,
                contactForm: <a href={`/${t('routes.consult')}`} target="_blank" />,
                tele2: <a href="https://www.tele2.lt/" target="_blank" />,
                telia: <a href="https://www.telia.lt/" target="_blank" />,
                bite: <a href="https://www.bite.lt/" target="_blank" />,
                teledema: <a href="https://www.teledema.lt/" target="_blank" />,
            }}>
                {text}
            </Trans>
        </>
    )
}

export default LegalPageSectionListItemTransComponent;
