import bannerImage from "/assets/images/why-opay-page-images/icon-about-feature-7.svg";
import LegalBanner from "../../components/privacy-policy-page-components/legal-banner/LegalBanner.tsx";
import GeneralTermsUpdatesSection
    from "../../components/general-terms-page-components/general-terms-updates-section/GeneralTermsUpdatesSection.tsx";
import {GeneralTermsArchiveUpdatesSectionConfig} from "../../configs/legal-pages/generalTermsArchiveUpdatesSection.ts";
import LegalPageSections
    from "../../components/privacy-policy-page-components/privacy-policy-sections/LegalPageSections.tsx";
import {GeneralTermsArchivePageSectionsConfig} from "../../configs/legal-pages/generalTermsArchiveSections.ts";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const GeneralTermsArchivePage = () => {
    return (
        <>
            <CustomHelmet title={'titles.general_terms_archive'} canonical={'routes.general_terms_archive'} />
            <LegalBanner heading={'general_terms'} image={bannerImage} />
            <GeneralTermsUpdatesSection config={GeneralTermsArchiveUpdatesSectionConfig}/>
            <LegalPageSections config={GeneralTermsArchivePageSectionsConfig} />
        </>
    )
}

export default GeneralTermsArchivePage;
