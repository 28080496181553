import bannerImage from "/assets/images/why-opay-page-images/icon-about-feature-6.svg";
import LegalBanner from "../../components/privacy-policy-page-components/legal-banner/LegalBanner.tsx";
import {PaymentTermsPageSectionsConfig} from "../../configs/legal-pages/paymentTermsSections.ts";
import LegalPageNumericListSections
    from "../../components/payment-terms-page-components/LegalPageNumericListSections.tsx";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const PaymentTermsPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.terms_of_payment'} canonical={'routes.terms_of_payment'} />
            <LegalBanner heading={'terms_of_payment'} image={bannerImage} />
            <ol className="opy-bg-white container opy-block">
                <LegalPageNumericListSections config={PaymentTermsPageSectionsConfig} />
            </ol>
        </>
    )
}

export default PaymentTermsPage;
