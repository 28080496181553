import {Trans, useTranslation} from "react-i18next";

const LegalPageSectionParagraphTransComponent = ({text, locale}: {text: string|undefined, locale?: string}) => {

    const {t} = locale ? useTranslation('translation', {lng: locale}) : useTranslation();

    return (
        <>
            <Trans t={t} components={{
                lrvComplaint: <a href="https://vdai.lrv.lt/lt/veiklos-sritys-1/skundu-nagrinejimas/" />,
                cookiesPolicy: <a href={`/${t('routes.cookies_policy')}`} target="_blank" />,
                cookiesPolicyEn: <a href={`/en/legal-information/cookies-policy`} target="_blank" />,
                privacyEmail: <a className='contact-by-email' href="mailto:privacy@opay.eu" />,
                contactEmail: <a className='contact-by-email' href="mailto:info@opay.eu" />,
                officerEmail: <a className='contact-by-email' href="mailto:info@privacypartners.lt" />,
                phone: <a href="tel:+37061155554" />,
                officerPhone: <a href="tel:+37052548240" />,
                privacyPolicy: <a href={`/${t('routes.privacy_policy')}`} target="_blank" />,
                privacyPolicyRussian: <a href={'/politika-konfidencialnosti'} target="_blank" />,
                opayWebsite: <a href="https://opay.eu" target="_blank" />,
                lbDisputes: <a href="https://www.lb.lt/lt/spreskite-ginca-su-finansiniu-paslaugu-teikeju#ex-1-4"
                               target="_blank" />,
                lb: <a href={t('lbLink')} target="_blank" />,
                lbInfo: <a href="mailto:info@lb.lt" target="_blank" />,
                frptLb: <a href="mailto:frpt@lb.lt" target="_blank" />,
                vvtat: <a href={t('vvtatLink')} target="_blank" />,
                ada: <a href={t('adaLink')} target="_blank" />,
                website: <a href="https://opay.eu" target="_blank" />,
                neopay: <a href="https://neopay.online/lt/term-of-service" target="_blank" />,
                dokobitLt: <a href="https://www.dokobit.com/lt" target="_blank" />,
                dokobitEn: <a href="https://www.dokobit.com" target="_blank" />,
            }}>
                {text}
            </Trans>
        </>
    )
}

export default LegalPageSectionParagraphTransComponent;
