import {Link} from "react-router-dom";
import {RefObject} from "react";

const CustomLink = (
    {to, className, children, menuContainerRef}:
        {to: string, className: string, children: string, menuContainerRef?: RefObject<HTMLDivElement>}
) => {
    return (
        <>
            <Link to={to} className={className}
                  onClick={() => menuContainerRef && menuContainerRef.current?.classList.remove('show')} >
                <span>{children}</span>
            </Link>
        </>
    )
}

export default CustomLink;
