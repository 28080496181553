import {Paragraph} from "../../@types/privacyPolicySectionResources.tsx";
import LegalPageSectionParagraphTransComponent
    from "../legal-pages-components/LegalPageSectionParagraphTransComponent.tsx";

const LegalPageNumericListItem = ({paragraph, locale}: {paragraph: Paragraph, locale?: string}) => {
    return (
        <>
            <li className="mb-4 text-blue-dark">
                <LegalPageSectionParagraphTransComponent text={paragraph.text} locale={locale} />
                {paragraph.list && <ol>
                    {paragraph.list.map((listItem, index) => (
                        <li key={index} className="mb-4 mt-4 text-blue-dark">
                            <LegalPageSectionParagraphTransComponent text={listItem.text} locale={locale} />
                        </li>
                    ))}
                </ol>}
            </li>
        </>
    )
}

export default LegalPageNumericListItem;
