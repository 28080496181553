import bannerImage from "/assets/images/why-opay-page-images/icon-about-feature-5.svg";
import LegalBanner from "../../components/privacy-policy-page-components/legal-banner/LegalBanner.tsx";
import LegalPageSections
    from "../../components/privacy-policy-page-components/privacy-policy-sections/LegalPageSections.tsx";
import {WhistleblowerPolicyPageSectionsConfig} from "../../configs/legal-pages/whistleblowerPolicySections.ts";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const WhistleblowerPolicyPage = () => {

    /*
    @TODO page exist only on EN language. Update routes.json, translation.json, footerLinks config after LT page
    creation.
    */

    return (
        <>
            <CustomHelmet title={'titles.whistleblower_policy'} canonical={'routes.whistleblower_policy'} />
            <LegalBanner heading={'whistleblower_policy'} image={bannerImage} />
            <LegalPageSections config={WhistleblowerPolicyPageSectionsConfig} />
        </>
    )
}

export default WhistleblowerPolicyPage;
