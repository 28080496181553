import HeaderMobileMenuButton from "../header-mobile-menu-button/HeaderMobileMenuButton.tsx";
import Logo from "../../logo/Logo.tsx";
import MenuSection from "../menu-section/MenuSection.tsx";
import ButtonsSection from "../buttons-section/ButtonsSection.tsx";
import {useRef} from "react";

const Header = () => {

    const menuContainerRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <nav className="opy-main-menu navbar fixed-top navbar-light navbar-expand-lg opy-main-menu">
                <div className="container opy-block">
                    <Logo className={'navbar-brand'}/>
                    <HeaderMobileMenuButton />
                    <div ref={menuContainerRef} className="navbar-collapse collapse" id="navbarNavDropdown">
                        <div className="container">
                            <MenuSection menuContainerRef={menuContainerRef} />
                            <ButtonsSection menuContainerRef={menuContainerRef} />
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header;
