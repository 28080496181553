import React, {Suspense} from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './fontkit.css'
import './scss/style.scss'
import './i18n/config.ts';
import {HelmetProvider} from "react-helmet-async";
import Loading from "./components/loading/Loading.tsx";

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
      <HelmetProvider>
          <Suspense fallback={<Loading display={'block'}/>}>
              <App />
          </Suspense>
      </HelmetProvider>
  </React.StrictMode>,
)
