import MainBanner from "../../components/home-page-components/main-banner/MainBanner.tsx";
import {IntegrationBannerButtonLinksConfig} from "../../configs/integration-page/integrationPageMainBanner.ts";
import mainBannerImage from '/assets/images/integration-page-images/plugins-image-1.svg'
import Plugins from "../../components/integration-page-components/plugins/Plugins.tsx";
import DevelopersBanner from "../../components/integration-page-components/developers-banner/DevelopersBanner.tsx";
import {documentation} from "../../configs/documentation/documentation.ts";
import developersBannerImage from '/assets/images/integration-page-images/plugins-image-2.svg'
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const IntegrationPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.integration'}
                          description={'integration_easily_integrate_description'}
                          canonical={'routes.integration'} />
            <MainBanner image={mainBannerImage}
                        links={IntegrationBannerButtonLinksConfig}
                        heading={'integration_easily_integrate'}
                        paragraph={'integration_easily_integrate_description'} />
            <Plugins />
            <DevelopersBanner heading={'implement_solutions'}
                              paragraph={'implement_solutions_description'}
                              buttonText={'implement_solutions_button_text'}
                              link={documentation.v1Url}
                              image={developersBannerImage} />
        </>
    )
}

export default IntegrationPage;
