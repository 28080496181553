import ThankYouBanner from "../../components/thank-you-banner/ThankYouBanner.tsx";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const DataReceivedThankYouPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.data_received_thank_you'}
                          canonical={'routes.data_received_thank_you'}
                          noIndex={true} />
            <ThankYouBanner text={'thank_you_we_send_you_email'} isNavigationToHome={false} />
        </>
    )
}

export default DataReceivedThankYouPage;
