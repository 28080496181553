import {useTranslation} from "react-i18next";
import {FollowUsSectionConfig} from "../../../configs/layout/footerLinks.ts";


const FollowUsSection = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="opy-copyright-right text-blue-dark">
                <span>{t('follow_us')}</span>
                {FollowUsSectionConfig.map((link, index) => (
                    <a href={link.link} key={index} target="_blank">
                        <img src={link.image} className={link.className} alt={link.name}/>
                    </a>
                ))}
            </div>
        </>
    )
}

export default FollowUsSection;
