import LegalBanner from "../../components/privacy-policy-page-components/legal-banner/LegalBanner.tsx";
import bannerImage from "/assets/images/why-opay-page-images/icon-about-feature-16.svg";
import LegalPageSections
    from "../../components/privacy-policy-page-components/privacy-policy-sections/LegalPageSections.tsx";
import {ForCandidatesPageSectionsConfig} from "../../configs/legal-pages/forCandidatesSections.ts";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const PrivacyPolicyPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.notice_for_candidates'} canonical={'routes.notice_for_candidates'} />
            <LegalBanner heading={'notice_for_candidates'} image={bannerImage} />
            <LegalPageSections config={ForCandidatesPageSectionsConfig} />
        </>
    )
}

export default PrivacyPolicyPage;
