import {useTranslation} from "react-i18next";
import FormColumns from "../pricing-page-components/pricing-form/FormColumns.tsx";
import {SubmitHandler, useForm} from "react-hook-form";
import {ConsultFormInputsConfig} from "../../configs/consult-page/consultFormInputsConfig.ts";
import {MessageConfigEnum} from "../../enum/message/MessageConfigEnum.ts";
import {ConsultInputs} from "../../@types/consultFormResources.tsx";
import {sendConsultForm} from "../../api";
import {useRef, useState} from "react";
import {log} from "../../logger/log.ts";
import {cleanUpWebsiteAddress, handleEmptyValues} from "../../helpers/pricing/formHelper.ts";
import {getFromIso2} from "../../@types/generics.tsx";

const ConsultForm = (
    {setSubmit, captcha, setCaptcha}:
        {setSubmit: (arg0: boolean) => void, captcha: boolean|null, setCaptcha: (arg0: boolean|null) => void}
) => {

    const {t, i18n} = useTranslation();
    const formContainerRef = useRef<HTMLDivElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const {register, handleSubmit} = useForm<ConsultInputs>();
    const [connectionError, setConnectionError] = useState<boolean>(false);

    const onSubmit: SubmitHandler<ConsultInputs> = async (data) => {

        setConnectionError(false);

        if (captcha === null || !captcha) {
            setCaptcha(false);
            return formContainerRef.current?.scrollIntoView();
        }

        data = handleEmptyValues(data);

        if (data.website_address !== null) {
            data.website_address = cleanUpWebsiteAddress(data.website_address);
        }

        data.config = MessageConfigEnum.STAFF_CONSULT;
        data.language = getFromIso2(i18n.language).toUpperCase();
        data.user_subject = t(data.subject);
        data.subject = `${t(data.subject)} | ${data.website_address !== null ? data.website_address : data.email}`
        data.email = data.email.toLowerCase();

        try {
            await sendConsultForm(data);
        } catch (error) {
            log.error(error);
            setConnectionError(true);
            return formRef.current?.scrollIntoView();
        }

        fbq('track', 'Lead', {content_type: "konsultuotis"});
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'consult_form_submission',
            'formLocation': 'registruotis_konsultacijai'
        });

        setSubmit(true);
        return formContainerRef.current?.scrollIntoView();
    }

    return (
        <>
            <div ref={formContainerRef} className="col-lg-6 mb-6 consult-form-container">
                <div className="opy-bg-blue-main p-5" style={{borderRadius: "12px"}}>
                    <div className="text-white fs-4 fw-semibold mb-4">{t('contact_us')}</div>
                    {captcha === false && <div className="notices error red">
                        <p>{t('check_captcha')}</p>
                    </div>}
                    {connectionError && <div className="notices error red"><p>
                        {t('connection_error')}
                    </p></div>}
                    <form ref={formRef} id="consult-form" onSubmit={handleSubmit(onSubmit)} className="consult-form">
                        <FormColumns config={ConsultFormInputsConfig}
                                     register={register}
                                     setCaptcha={setCaptcha}
                                     linkClassName={'text-decoration-underline text-white'}
                                     isConsult={true} />
                        <div className="buttons mt-4">
                            <button
                                type="submit"
                                className="button d-block opy-button-orange text-uppercase fw-medium w-100">
                                {t('submit')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ConsultForm;
