import {Section} from "../../@types/privacyPolicySectionResources.tsx";
import LegalPageNumericList from "./LegalPageNumericList.tsx";

const LegalPageNumericListSections = ({config, locale}: {config: Section[], locale?: string}) => {
    return (
        <>
            {
                config.map((section, index) => (
                    <LegalPageNumericList key={index} section={section} locale={locale} />
                ))
            }
        </>
    )
}

export default LegalPageNumericListSections;
