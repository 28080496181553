import {FormSectionType} from "../../@types/forTestingFormSectionsResources.ts";
import {handlePhoneNumber, handlePhonePrefix} from "../../helpers/register-form/registerFormHelper.tsx";

export const AcceptPaymentsPageFormSectionsCompanyConfig: FormSectionType[] = [
    {
        rows: [
            {
                inputs: [
                    {
                        label: 'company_name',
                        registerLabel: 'company_merchant_name',
                        required: false,
                        type: 'text',
                        singleInput: true,
                        onchange: () => {},
                    },
                    {
                        label: 'merchant_company_code',
                        registerLabel: 'company_merchant_code',
                        required: false,
                        type: 'text',
                        singleInput: true,
                        onchange: () => {},
                    }
                ]
            },
            {
                inputs: [
                    {
                        label: 'merchant_address',
                        registerLabel: 'company_merchant_address',
                        required: false,
                        type: 'text',
                        hint: 'merchant_address_hint',
                        singleInput: true,
                        onchange: () => {},
                    },
                    {
                        label: 'contact_email',
                        registerLabel: 'company_merchant_email',
                        required: false,
                        type: 'email',
                        hint: 'merchant_email_hint_company',
                        singleInput: true,
                        onchange: () => {},
                    }
                ]
            },
            {
                inputs: [
                    {
                        label: 'contact_phone_number',
                        registerLabel: 'company_merchant_phone_number_prefix',
                        required: false,
                        type: 'tel',
                        initialValue: '+370',
                        pattern: '.{2,}',
                        maxLength: 4,
                        secondInput: {
                            registerLabel: 'company_merchant_phone_number',
                            required: false,
                            type: 'tel',
                            maxLength: 16,
                            onchange: (e) => handlePhoneNumber(e),
                        },
                        hint: 'merchant_phone_number_hint',
                        onchange: (e) => handlePhonePrefix(e)
                    },
                    {
                        label: 'bank_account_number',
                        registerLabel: 'company_agreement_bank_account',
                        required: false,
                        type: 'text',
                        hint: 'bank_account_number_hint',
                        singleInput: true,
                        onchange: () => {},
                    }
                ]
            }
        ]
    },
    {
        heading: 'company_director_information',
        rows: [
            {
                inputs: [
                    {
                        label: 'name',
                        registerLabel: 'merchant_representative_name',
                        required: false,
                        type: 'text',
                        singleInput: true,
                        onchange: () => {},
                    },
                    {
                        label: 'surname',
                        registerLabel: 'merchant_representative_surname',
                        required: false,
                        type: 'text',
                        singleInput: true,
                        onchange: () => {},
                    }
                ]
            },
            {
                inputs: [
                    {
                        label: 'email',
                        registerLabel: 'merchant_representative_email',
                        required: false,
                        type: 'email',
                        singleInput: true,
                        hint: 'merchant_email_hint_individual',
                        onchange: () => {},
                    }
                ]
            }
        ]
    }
]
