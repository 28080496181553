import MainBanner from "../../components/home-page-components/main-banner/MainBanner.tsx";
import mainBannerImage from '/assets/images/faq-page-images/faq-image-1.svg'
import {FaqBannerButtonLinksConfig} from "../../configs/faq-page/faqPageMainBanner.ts";
import FaqAccordion from "../../components/faq-page-components/faq-accordion/FaqAccordion.tsx";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const FaqPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.faq'}
                          description={'faq_banner_description'}
                          canonical={'routes.faq'} />
            <MainBanner image={mainBannerImage}
                        links={FaqBannerButtonLinksConfig}
                        heading={'faq'}
                        paragraph={'faq_banner_description'} />
            <FaqAccordion />
        </>
    )
}

export default FaqPage;
