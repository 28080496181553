import LegalPageSection from "./LegalPageSection.tsx";
import {Section} from "../../../@types/privacyPolicySectionResources.tsx";

const LegalPageSections = ({config, locale}: {config: Section[], locale?: string}) => {
    return (
        <>
            {config.map((section, index) => (
                <LegalPageSection key={index} section={section} locale={locale} />
            ))}
        </>
    )
}

export default LegalPageSections;
