import DemoBannerButtons from "../../components/demo-pages-components/demo-banner/DemoBannerButtons.tsx";
import BannerWithElement from "../../components/demo-pages-components/demo-banner/BannerWithElement.tsx";
import DemoDescription from "../../components/demo-pages-components/DemoDescription.tsx";
import PurchaseExample from "../../components/demo-pages-components/purchase-example/PurchaseExample.tsx";
import Solutions from "../../components/accelerating-online-page-components/solutions/Solutions.tsx";
import DemonstrationsCards from "../../components/demonstrations/DemonstrationsCards.tsx";
import GetStartedBanner from "../../components/home-page-components/get-started-banner/GetStartedBanner.tsx";
import {DemoPisSolutionsListConfig} from "../../configs/demo-pages/demoPisSolutionsListConfig.ts";
import {PisDemonstrationsCardsConfig} from "../../configs/demo-pages/pisDemoCards.ts";
import {useState} from "react";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const DemoPisPage = () => {

    const [channelsInCheckout, setChannelsInCheckout] = useState(true);

    return (
        <>
            <CustomHelmet title={'titles.demo_pis_payments'} canonical={'routes.demonstrations_pis_payments'} />
            <div className="opy-content">
                <div className="opy-page-demo-payment">
                    <BannerWithElement heading={'pis_demo'}
                                       element={<DemoBannerButtons
                                           channelsInCheckout={channelsInCheckout}
                                           setChannelsInCheckout={setChannelsInCheckout} />}
                                       slider={false} />
                    <DemoDescription />
                    <PurchaseExample page={'pis'} channelsInCheckout={channelsInCheckout} />
                    <Solutions solutions={DemoPisSolutionsListConfig} />
                    <DemonstrationsCards config={PisDemonstrationsCardsConfig} />
                    <GetStartedBanner bannerText={'boost_your_business'}
                                      linkText={'get_started'}
                                      linkClassname={'opy-button opy-button--white opy-button--medium text-center'}
                                      linkTo={'routes.start_using_accept_payments'} />
                </div>
            </div>
        </>
    )
}

export default DemoPisPage;
