import {Section} from "../../@types/privacyPolicySectionResources.tsx";
import {Language} from "../../@types/generics.tsx";

export const PaymentTermsPageSectionsConfig: Section[] = [
    {
        heading: 'payment_terms_definitions',
        paragraphs: [
            {
                text: 'payment_terms_definitions_paragraph_1',
            },
            {
                text: 'payment_terms_definitions_paragraph_2',
            },
            {
                text: 'payment_terms_definitions_paragraph_3',
            },
            {
                text: 'payment_terms_definitions_paragraph_4',
            },
            {
                text: 'payment_terms_definitions_paragraph_5',
            },
            {
                text: 'payment_terms_definitions_paragraph_6',
            },
            {
                text: 'payment_terms_definitions_paragraph_7',
            },
            {
                text: 'payment_terms_definitions_paragraph_8',
            },
            {
                text: 'payment_terms_definitions_paragraph_9',
            },
            {
                text: 'payment_terms_definitions_paragraph_10',
            },
            {
                text: 'payment_terms_definitions_paragraph_11',
            },
            {
                text: 'payment_terms_definitions_paragraph_12',
            },
            {
                text: 'payment_terms_definitions_paragraph_13',
            },
            {
                text: 'payment_terms_definitions_paragraph_14',
            },
            {
                text: 'payment_terms_definitions_paragraph_15',
            },
            {
                text: 'payment_terms_definitions_paragraph_16',
            },
            {
                text: 'payment_terms_definitions_paragraph_17',
            },
            {
                text: 'payment_terms_definitions_paragraph_18',
            },
            {
                text: 'payment_terms_definitions_paragraph_19',
            },
            {
                text: 'payment_terms_definitions_paragraph_20',
            },
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'payment_terms_general_provisions',
        paragraphs: [
            {
                text: 'payment_terms_general_provisions_paragraph_1',
            },
            {
                text: 'payment_terms_general_provisions_paragraph_2',
            },
            {
                text: 'payment_terms_general_provisions_paragraph_3',
            },
            {
                text: 'payment_terms_general_provisions_paragraph_4',
            },
            {
                text: 'payment_terms_general_provisions_paragraph_5',
            },
            {
                text: 'payment_terms_general_provisions_paragraph_6',
            },
            {
                text: 'payment_terms_general_provisions_paragraph_7',
            },
            {
                text: 'payment_terms_general_provisions_paragraph_8',
            },
            {
                text: 'payment_terms_general_provisions_paragraph_9',
            },
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'payment_terms_features',
        paragraphs: [
            {
                text: 'payment_terms_features_paragraph_1',
                list: [
                    {
                        text: 'payment_terms_features_paragraph_1_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_7',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_8',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_9',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_10',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_11',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_12',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_13',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_14',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_15',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_16',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_1_list_17',
                        languageToShow: [Language.LT, Language.EN]
                    },
                ]
            },
            {
                text: 'payment_terms_features_paragraph_2',
                list: [
                    {
                        text: 'payment_terms_features_paragraph_2_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_2_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_2_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_2_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_2_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                ]
            },
            {
                text: 'payment_terms_features_paragraph_3',
                list: [
                    {
                        text: 'payment_terms_features_paragraph_3_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_3_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_3_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_3_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                ]
            },
            {
                text: 'payment_terms_features_paragraph_4',
                list: [
                    {
                        text: 'payment_terms_features_paragraph_4_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_4_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_4_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_4_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_4_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_4_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    },
                ]
            },
            {
                text: 'payment_terms_features_paragraph_5',
                list: [
                    {
                        text: 'payment_terms_features_paragraph_5_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_5_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_5_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_5_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                ]
            },
            {
                text: 'payment_terms_features_paragraph_6',
                list: [
                    {
                        text: 'payment_terms_features_paragraph_6_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_6_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_6_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'payment_terms_features_paragraph_6_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                ]
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'payment_terms_use_of_remember_me',
        paragraphs: [
            {
                text: 'payment_terms_use_of_remember_me_paragraph_1',
            },
            {
                text: 'payment_terms_use_of_remember_me_paragraph_2',
            },
            {
                text: 'payment_terms_use_of_remember_me_paragraph_3',
            },
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'payment_terms_data_protection',
        paragraphs: [
            {
                text: 'payment_terms_data_protection_paragraph_1',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'payment_terms_liability',
        paragraphs: [
            {
                text: 'payment_terms_liability_paragraph_1',
            },
            {
                text: 'payment_terms_liability_paragraph_2',
            },
            {
                text: 'payment_terms_liability_paragraph_3',
            },
            {
                text: 'payment_terms_liability_paragraph_4',
            },
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'payment_terms_final_provisions',
        paragraphs: [
            {
                text: 'payment_terms_final_provisions_paragraph_1',
            },
            {
                text: 'payment_terms_final_provisions_paragraph_2',
            },
            {
                text: 'payment_terms_final_provisions_paragraph_3',
            },
            {
                text: 'payment_terms_final_provisions_paragraph_4',
            },
            {
                text: 'payment_terms_final_provisions_paragraph_5',
            },
            {
                text: 'payment_terms_final_provisions_paragraph_6',
            },
            {
                text: 'payment_terms_final_provisions_paragraph_7',
            },
        ],
        languageToShow: [Language.LT, Language.EN]
    }
]
