import bannerImage from "/assets/images/why-opay-page-images/icon-about-feature-10.svg";
import LegalBanner from "../../components/privacy-policy-page-components/legal-banner/LegalBanner.tsx";
import LegalPageSections
    from "../../components/privacy-policy-page-components/privacy-policy-sections/LegalPageSections.tsx";
import {SigningOfDocumentsPageSectionsConfig} from "../../configs/legal-pages/signingOfDocumentsSections.ts";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const SigningOfDocumentsPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.signing_of_documents'} canonical={'routes.signing_of_documents'} />
            <LegalBanner heading={'signing_of_documents'} image={bannerImage} />
            <LegalPageSections config={SigningOfDocumentsPageSectionsConfig} />
        </>
    )
}

export default SigningOfDocumentsPage;
