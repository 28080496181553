import RequisitesAndContacts
    from "../../components/contacts-page-components/requisites-and-contacts/RequisitesAndContacts.tsx";
import GoogleMap from "../../components/contacts-page-components/google-map/GoogleMap.tsx";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const ContactsPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.contacts'} canonical={'routes.contacts'} />
            <RequisitesAndContacts />
            <GoogleMap />
        </>
    )
}

export default ContactsPage;
