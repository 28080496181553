import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";

const CustomHelmet = (
    {title, description, canonical, noIndex}:
        {title: string, description?: string, canonical?: string, noIndex?: boolean}
) => {

    const {t} = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t(title)}</title>
                {description && <meta name="description" content={t(description)}/>}
                {canonical && <link rel="canonical" href={`${import.meta.env.VITE_APP_URL}/${t(canonical)}`}/>}
                {noIndex && <meta name="robots" content="noindex, nofollow"/>}
            </Helmet>
        </>
    )
}

export default CustomHelmet;
