import CenteredBannerNoImage
    from "../../components/legal-information-page-components/centered-banner-no-image/CenteredBannerNoImage.tsx";
import LinkableCardsBanner
    from "../../components/payment-methods-page-components/linkable-cards-banner/LinkableCardsBanner.tsx";
import {LegalInformationPageLinkableCardsConfig} from "../../configs/legal-pages/legalInformationPageLinkableCards.ts";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const LegalInformationPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.legal_information');
    }, []);

    return (
        <>
            <CustomHelmet title={'titles.legal_information'}
                          description={'legal_information_banner_text'}
                          canonical={'routes.legal_information'} />
            <CenteredBannerNoImage heading={'legal_information'} paragraph={'legal_information_banner_text'} />
            <LinkableCardsBanner cards={LegalInformationPageLinkableCardsConfig} />
        </>
    )
}

export default LegalInformationPage;
