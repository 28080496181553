import {Trans, useTranslation} from "react-i18next";
import {Paragraph} from "../../../@types/privacyPolicySectionResources.tsx";
import i18n from "i18next";
import LegalPageSectionParagraphTransComponent
    from "../../legal-pages-components/LegalPageSectionParagraphTransComponent.tsx";
import {getCurrentLanguageEnum} from "../../../@types/generics.tsx";
import LegalPageSectionParagraphText from "./LegalPageSectionParagraphText.tsx";

const LegalPageSectionParagraph = ({paragraph, locale}: {paragraph: Paragraph, locale?: string}) => {

    const {t} = locale ? useTranslation('translation', {lng: locale}) : useTranslation();

    return (
        <>
            {paragraph.text && <p className="mb-4 text-blue-dark">
                    <LegalPageSectionParagraphTransComponent text={paragraph.text} locale={locale} />
                </p>}
            {paragraph.list && paragraph.list.map((listItem, index) => (
                <div key={index}>
                    {listItem.text
                        && listItem.languageToShow.includes(getCurrentLanguageEnum(i18n.language))
                        && <LegalPageSectionParagraphText text={listItem.text} locale={locale} />}
                    <ul>
                        {listItem.subList && listItem.subList.map((subListItem, index) => (
                            <li key={index} className={subListItem.className}>
                                <Trans t={t}>
                                    {subListItem.text}
                                </Trans>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
            {paragraph.note && <p className="mb-4 text-blue-dark" style={{fontStyle: 'italic', marginLeft: '5rem'}}>
                {t(paragraph.note)}
            </p>}
        </>
    )
}

export default LegalPageSectionParagraph;
