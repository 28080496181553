import LegalBanner from "../../components/privacy-policy-page-components/legal-banner/LegalBanner.tsx";
import bannerImage from "/assets/images/why-opay-page-images/icon-about-feature-16.svg";
import LegalPageSections
    from "../../components/privacy-policy-page-components/privacy-policy-sections/LegalPageSections.tsx";
import {PrivacyPolicyPageSectionsConfig} from "../../configs/legal-pages/privacyPolicySections.ts";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const PrivacyPolicyPageRussian = () => {
    return (
        <>
            <CustomHelmet title={'Политика конфиденциальности| OPAY'} noIndex={true} />
            <div style={{marginTop: '-80px', marginBottom: '10rem'}}>
                <LegalBanner heading={'privacy_policy'} image={bannerImage} locale={'ru'} />
                <LegalPageSections config={PrivacyPolicyPageSectionsConfig} locale={'ru'} />
            </div>
        </>
    )
}

export default PrivacyPolicyPageRussian;
