import bannerImage from "/assets/images/why-opay-page-images/icon-about-feature-6.svg";
import LegalBanner from "../../components/privacy-policy-page-components/legal-banner/LegalBanner.tsx";
import {PaymentTermsPageSectionsConfig} from "../../configs/legal-pages/paymentTermsSections.ts";
import LegalPageNumericListSections
    from "../../components/payment-terms-page-components/LegalPageNumericListSections.tsx";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const PaymentTermsPageRussian = () => {
    return (
        <>
            <CustomHelmet title={'Условия оплаты | OPAY'} noIndex={true} />
            <div style={{marginTop: '-80px'}}>
                <LegalBanner heading={'terms_of_payment'} image={bannerImage} locale={'ru'} />
                <ol className="opy-bg-white container opy-block" style={{marginBottom: '10rem'}}>
                    <LegalPageNumericListSections config={PaymentTermsPageSectionsConfig} locale={'ru'} />
                </ol>
            </div>
        </>
    )
}

export default PaymentTermsPageRussian;
