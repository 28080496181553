import LegalPageSectionListItemTransComponent
    from "../../legal-pages-components/LegalPageSectionListItemTransComponent.tsx";

const LegalPageSectionParagraphText = ({text, locale}: {text: string, locale?: string}) => {
    return (
        <>
            <p className="mb-4 checkmark text-blue-dark">
                <LegalPageSectionListItemTransComponent text={text} locale={locale}/>
            </p>
        </>
    )
}

export default LegalPageSectionParagraphText;
