import {MenuSectionConfig} from "../../../configs/layout/headerMenuSection.ts";
import MenuSectionItem from "./MenuSectionItem.tsx";
import {RefObject} from "react";


const MenuSection = ({menuContainerRef}: {menuContainerRef: RefObject<HTMLDivElement> }) => {
    return (
        <>
            <ul className="navbar-nav">
                {MenuSectionConfig.map((item, index) => (
                    <MenuSectionItem action={item.action}
                                     name={item.name}
                                     key={index}
                                     menuContainerRef={menuContainerRef} />
                ))}
            </ul>
        </>
    )
}

export default MenuSection;
