import CenteredBannerNoImage
    from "../../components/legal-information-page-components/centered-banner-no-image/CenteredBannerNoImage.tsx";
import LinkableCardsBanner
    from "../../components/payment-methods-page-components/linkable-cards-banner/LinkableCardsBanner.tsx";
import {DemonstrationsPageLinkableCardsConfig} from "../../configs/demo-pages/demonstrationsPageLinkableCards.ts";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const DemonstrationsPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.demonstrations'}
                          description={'demonstrations_banner_text'}
                          canonical={'routes.demonstrations'} />
            <CenteredBannerNoImage heading={'demonstrations'} paragraph={'demonstrations_banner_text'} />
            <LinkableCardsBanner cards={DemonstrationsPageLinkableCardsConfig} />
        </>
    )
}

export default DemonstrationsPage;
