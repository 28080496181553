import BannerWithElement from "../../components/demo-pages-components/demo-banner/BannerWithElement.tsx";
import StartUsingBannerButtons from "../../components/for-testing-page-components/StartUsingBannerButtons.tsx";
import StartUsingDescription from "../../components/for-testing-page-components/StartUsingDescription.tsx";
import AcceptPaymentsForm from "../../components/accept-payments-page-components/form/AcceptPaymentsForm.tsx";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const AcceptPaymentsPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.accept_payments'}
                          description={'fill_form_to_accept_payments'}
                          canonical={'routes.start_using_accept_payments'} />
            <div className="opy-content">
                <BannerWithElement heading={'start_using'}
                                   element={<StartUsingBannerButtons isForTestingPage={false}/>}
                                   slider={false}/>
                <div className="clearfix" style={{paddingTop: '50px'}}>
                    <StartUsingDescription description1={'fill_form_to_accept_payments'}
                                           description2={'after_start_using_form_filling'}/>
                    <AcceptPaymentsForm />
                </div>
            </div>
        </>
    )
}

export default AcceptPaymentsPage;
