import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PageTemplate from "./components/layout/page-template/PageTemplate.tsx";
import HomePage from "./pages/home-page/HomePage.tsx";
import WhyOpayPage from "./pages/why-opay-page/WhyOpayPage.tsx";
import PaymentMethodsPage from "./pages/payment-methods-page/PaymentMethodsPage.tsx";
import BuildingOnlinePage from "./pages/building-online-page/BuildingOnlinePage.tsx";
import AcceleratingOnlinePage from "./pages/accelerating-online-page/AcceleratingOnlinePage.tsx";
import CustomSolutionsPage from "./pages/custom-solutions-page/CustomSolutionsPage.tsx";
import IntegrationPage from "./pages/integration-page/IntegrationPage.tsx";
import ContactsPage from "./pages/contacts-page/ContactsPage.tsx";
import FaqPage from "./pages/faq-page/FaqPage.tsx";
import LegalInformationPage from "./pages/legal-information-page/LegalInformationPage.tsx";
import PrivacyPolicyPage from "./pages/privacy-policy-page/PrivacyPolicyPage.tsx";
import CookiesPolicyPage from "./pages/cookies-policy-page/CookiesPolicyPage.tsx";
import GeneralTermsPage from "./pages/general-terms-page/GeneralTermsPage.tsx";
import ComplaintSubmissionPage from "./pages/complaint-submission-page/ComplaintSubmissionPage.tsx";
import WhistleblowerPolicyPage from "./pages/whistleblower-policy-page/WhistleblowerPolicyPage.tsx";
import GeneralTermsArchivePage from "./pages/general-terms-archive-page/GeneralTermsArchivePage.tsx";
import SigningOfDocumentsPage from "./pages/signing-of-documents-page/SigningOfDocumentsPage.tsx";
import OnlineBankingPage from "./pages/online-banking-page/OnlineBankingPage.tsx";
import PaymentInitiationPage from "./pages/payment-initiation-page/PaymentInitiationPage.tsx";
import CardPaymentsPage from "./pages/card-payments-page/CardPaymentsPage.tsx";
import CashPaymentsPage from "./pages/cash-payments-page/CashPaymentsPage.tsx";
import InstallmentPaymentsPage from "./pages/installment-payments-page/InstallmentPaymentsPage.tsx";
import WireTransfersPage from "./pages/wire-transfers-page/WireTransfersPage.tsx";
import PricingPage from "./pages/pricing-page/PricingPage.tsx";
import DemoBankLinkPage from "./pages/demo-bank-link-page/DemoBankLinkPage.tsx";
import DemoDonationsPage from "./pages/demo-donations-page/DemoDonationsPage.tsx";
import DemoSelfServicePage from "./pages/demo-self-service-page/DemoSelfServicePage.tsx";
import DemoPisPage from "./pages/demo-pis-page/DemoPisPage.tsx";
import DemonstrationsPage from "./pages/demonstrations-page/DemonstrationsPage.tsx";
import ConsultPage from "./pages/consult-page/ConsultPage.tsx";
import SolutionsShopifyPage from "./pages/solutions-shopify-page/SolutionsShopifyPage.tsx";
import ClientConsentPage from "./pages/client-consent-page/ClientConsentPage.tsx";
import ForTestingPage from "./pages/for-testing-page/ForTestingPage.tsx";
import DataReceivedThankYouPage from "./pages/data-received-thank-you-page/DataReceivedThankYouPage.tsx";
import AcceptPaymentsPage from "./pages/accept-payments-page/AcceptPaymentsPage.tsx";
import PaymentSuccessfulThankYouPage from "./pages/payment-successful-thank-you-page/PaymentSuccessfulThankYouPage.tsx";
import Default from "./pages/Default.tsx";
import ForCandidatesPage from "./pages/for-candidates-page/ForCandidatesPage.tsx";
import IdentityConfirmedPage from "./pages/identity-confirmed-page/IdentityConfirmedPage.tsx";
import LegacyRedirect from "./legacy-redirect/LegacyRedirect.tsx";
import {useEffect} from "react";
import PaymentTermsPage from "./pages/payment-terms-page/PaymentTermsPage.tsx";
import PaymentTermsPageRussian from "./pages/payment-terms-page-russian/PaymentTermsPageRussian.tsx";
import PrivacyPolicyPageRussian from "./pages/privacy-policy-page-russian/PrivacyPolicyPageRussian.tsx";

const App = () => {

    const { t } = useTranslation();
    const currentPath = window.location.pathname;

    useEffect(() => {
        if (currentPath !== '/' && currentPath.endsWith('/')) {
            const newPath = currentPath.slice(0, -1);

            window.history.replaceState(null, '', newPath);
        }
    }, []);

    const routes = createBrowserRouter([
        {
            path: '/',
            element: <Default />
        },
        {
            path: '*',
            element: <LegacyRedirect />
        },
        {
            id: 'payment-terms-russian',
            path: `/usloviya-oplaty`,
            element: <PaymentTermsPageRussian />,
        },
        {
            id: 'privacy-policy-russian',
            path: `/politika-konfidencialnosti`,
            element: <PrivacyPolicyPageRussian />,
        },
        {
            path: '/',
            element: <PageTemplate />,
            children: [
                {
                    id: 'home',
                    path: `/${t('routes.language')}`,
                    element: <HomePage />,
                },
                {
                    id: 'why-opay',
                    path: `/${t('routes.about_opay')}`,
                    element: <WhyOpayPage />,
                },
                {
                    id: 'payment-methods',
                    path: `/${t('routes.payment_methods')}`,
                    element: <PaymentMethodsPage />,
                },
                {
                    id: 'building-online-business',
                    path: `/${t('routes.building_online_business')}`,
                    element: <BuildingOnlinePage />,
                },
                {
                    id: 'accelerating-online-business',
                    path: `/${t('routes.accelerating_online_business')}`,
                    element: <AcceleratingOnlinePage />,
                },
                {
                    id: 'custom-payments-solutions',
                    path: `/${t('routes.custom_payments_solutions')}`,
                    element: <CustomSolutionsPage />,
                },
                {
                    id: 'integration',
                    path: `/${t('routes.integration')}`,
                    element: <IntegrationPage />,
                },
                {
                    id: 'contacts',
                    path: `/${t('routes.contacts')}`,
                    element: <ContactsPage />,
                },
                {
                    id: 'faq',
                    path: `/${t('routes.faq')}`,
                    element: <FaqPage />,
                },
                {
                    id: 'legal-information',
                    path: `/${t('routes.legal_information')}`,
                    element: <LegalInformationPage />,
                },
                {
                    id: 'privacy-policy',
                    path: `/${t('routes.privacy_policy')}`,
                    element: <PrivacyPolicyPage />,
                },
                {
                    id: 'cookies-policy',
                    path: `/${t('routes.cookies_policy')}`,
                    element: <CookiesPolicyPage />,
                },
                {
                    id: 'general-terms',
                    path: `/${t('routes.general_terms')}`,
                    element: <GeneralTermsPage />,
                },
                {
                    id: 'general-terms-archive',
                    path: `/${t('routes.general_terms_archive')}`,
                    element: <GeneralTermsArchivePage />,
                },
                {
                    id: 'payment-terms',
                    path: `/${t('routes.payment_terms')}`,
                    element: <PaymentTermsPage />,
                },
                {
                    id: 'complaint-submission',
                    path: `/${t('routes.complaint_submission')}`,
                    element: <ComplaintSubmissionPage />,
                },
                {
                    id: 'whistleblower-policy',
                    path: `/${t('routes.whistleblower_policy')}`,
                    element: <WhistleblowerPolicyPage />,
                },
                {
                    id: 'signing-of-documents',
                    path: `/${t('routes.signing_of_documents')}`,
                    element: <SigningOfDocumentsPage />,
                },
                {
                    id: 'online-banking',
                    path: `/${t('routes.payment_methods_online_banking')}`,
                    element: <OnlineBankingPage />,
                },
                {
                    id: 'payment-initiation',
                    path: `/${t('routes.payment_methods_payment_initiation')}`,
                    element: <PaymentInitiationPage />,
                },
                {
                    id: 'card-payments',
                    path: `/${t('routes.payment_methods_card_payments')}`,
                    element: <CardPaymentsPage />,
                },
                {
                    id: 'cash-payments',
                    path: `/${t('routes.payment_methods_cash_payments')}`,
                    element: <CashPaymentsPage />,
                },
                {
                    id: 'installment-payments',
                    path: `/${t('routes.payment_methods_installment_payments')}`,
                    element: <InstallmentPaymentsPage />,
                },
                {
                    id: 'wire-transfers',
                    path: `/${t('routes.payment_methods_bank_transfer')}`,
                    element: <WireTransfersPage />,
                },
                {
                    id: 'pricing',
                    path: `/${t('routes.pricing')}`,
                    element: <PricingPage />,
                },
                {
                    id: 'demo-bank-link',
                    path: `/${t('routes.demonstrations_bank_link_payments')}`,
                    element: <DemoBankLinkPage />,
                },
                {
                    id: 'demo-pis',
                    path: `/${t('routes.demonstrations_pis_payments')}`,
                    element: <DemoPisPage />,
                },
                {
                    id: 'demo-donations',
                    path: `/${t('routes.demonstrations_donations')}`,
                    element: <DemoDonationsPage />,
                },
                {
                    id: 'demo-self-service',
                    path: `/${t('routes.demonstrations_self_service')}`,
                    element: <DemoSelfServicePage />,
                },
                {
                    id: 'demonstrations',
                    path: `/${t('routes.demonstrations')}`,
                    element: <DemonstrationsPage />,
                },
                {
                    id: 'consult',
                    path: `/${t('routes.consult')}`,
                    element: <ConsultPage />,
                },
                {
                    id: 'solutions-shopify',
                    path: `/${t('routes.solutions_shopify')}`,
                    element: <SolutionsShopifyPage />,
                },
                {
                    id: 'client-consent',
                    path: `/${t('routes.client_consent')}`,
                    element: <ClientConsentPage />,
                },
                {
                    id: 'for-testing',
                    path: `/${t('routes.start_using_install_for_test')}`,
                    element: <ForTestingPage />,
                },
                {
                    id: 'accept-payments',
                    path: `/${t('routes.start_using_accept_payments')}`,
                    element: <AcceptPaymentsPage />,
                },
                {
                    id: 'data-received-thank-you-page',
                    path: `/${t('routes.data_received_thank_you')}`,
                    element: <DataReceivedThankYouPage />,
                },
                {
                    id: 'identity-confirmed',
                    path: `/${t('routes.identity_confirmed')}`,
                    element: <IdentityConfirmedPage />,
                },
                {
                    id: 'payment-successful-thank-you-page',
                    path: `/${t('routes.payment_successful_thank_you')}`,
                    element: <PaymentSuccessfulThankYouPage />,
                },
                {
                    id: 'for-candidates-page',
                    path: `/${t('routes.notice_for_candidates')}`,
                    element: <ForCandidatesPage />,
                }
            ]
        }
    ]);

    return (
        <>
            <RouterProvider router={routes}/>
        </>
    )
}

export default App
