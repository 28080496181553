import BannerWithElement from "../../components/demo-pages-components/demo-banner/BannerWithElement.tsx";
import DemoBannerCarousel from "../../components/demo-pages-components/demo-banner/DemoBannerCarousel.tsx";
import {SelfServiceDemoCardsConfig} from "../../configs/demo-pages/selfServiceDemoCarouselCards.ts";
import DemoAdvantages from "../../components/demo-pages-components/demo-advantages/DemoAdvantages.tsx";
import {SelfServiceDemoAdvantagesConfig} from "../../configs/demo-pages/selfServiceDemoAdvantages.ts";
import SelfServiceNotificationsDescription
    from "../../components/demo-pages-components/SelfServiceNotificationsDescription.tsx";
import DemonstrationsCards from "../../components/demonstrations/DemonstrationsCards.tsx";
import Solutions from "../../components/accelerating-online-page-components/solutions/Solutions.tsx";
import GetStartedBanner from "../../components/home-page-components/get-started-banner/GetStartedBanner.tsx";
import {DemoSelfServiceSolutionsListConfig} from "../../configs/demo-pages/demoSelfServiceSolutionsListConfig.ts";
import {SelfServiceDemonstrationsCardsConfig} from "../../configs/demo-pages/selfServiceDemoCards.ts";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const DemoSelfServicePage = () => {
    return (
        <>
            <CustomHelmet title={'titles.demo_self_service'} canonical={'routes.demonstrations_self_service'} />
            <div className="opy-content">
                <div className="opy-page-demo-donation">
                    <BannerWithElement heading={'demonstration_self_service'}
                                       element={<DemoBannerCarousel
                                    config={SelfServiceDemoCardsConfig} />}
                                       slider={true} />
                    <DemoAdvantages config={SelfServiceDemoAdvantagesConfig} />
                    <SelfServiceNotificationsDescription />
                    <Solutions solutions={DemoSelfServiceSolutionsListConfig} />
                    <DemonstrationsCards config={SelfServiceDemonstrationsCardsConfig} />
                    <GetStartedBanner bannerText={'boost_your_business'}
                                      linkText={'get_started'}
                                      linkClassname={'opy-button opy-button--white opy-button--medium text-center'}
                                      linkTo={'routes.start_using_accept_payments'} />
                </div>
            </div>
        </>
    )
}

export default DemoSelfServicePage;
