import ThankYouBanner from "../../components/thank-you-banner/ThankYouBanner.tsx";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const PaymentSuccessfulThankYouPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.payment_successful_thank_you'}
                          canonical={'routes.payment_successful_thank_you'}
                          noIndex={true} />
            <ThankYouBanner text={'thank_you_payment_successful'} isNavigationToHome={false} />
        </>
    )
}

export default PaymentSuccessfulThankYouPage;
