import ThankYouBanner from "../../components/thank-you-banner/ThankYouBanner.tsx";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const IdentityConfirmedPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.identity_confirmed'} canonical={'routes.identity_confirmed'} />
            <ThankYouBanner text={'identity_confirmed'} isNavigationToHome={true} />
        </>
    )
}

export default IdentityConfirmedPage;
