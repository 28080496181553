import BannerWithElement from "../../components/demo-pages-components/demo-banner/BannerWithElement.tsx";
import StartUsingBannerButtons from "../../components/for-testing-page-components/StartUsingBannerButtons.tsx";
import StartUsingDescription from "../../components/for-testing-page-components/StartUsingDescription.tsx";
import ForTestingForm from "../../components/for-testing-page-components/form/ForTestingForm.tsx";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const ForTestingPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.for_testing'}
                          description={'fill_form_for_testing'}
                          canonical={'routes.start_using_install_for_test'} />
            <div className="opy-content">
                <BannerWithElement heading={'start_using'}
                                   element={<StartUsingBannerButtons isForTestingPage={true} />}
                                   slider={false} />
                <div className="clearfix" style={{paddingTop: '50px'}}>
                    <StartUsingDescription description1={'fill_form_for_testing'}
                                       description2={'after_start_using_form_filling'} />
                    <ForTestingForm />
                </div>
            </div>
        </>
    )
}

export default ForTestingPage;
