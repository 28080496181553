import bannerImage from "/assets/images/why-opay-page-images/icon-about-feature-10.svg";
import LegalBanner from "../../components/privacy-policy-page-components/legal-banner/LegalBanner.tsx";
import LegalPageSections
    from "../../components/privacy-policy-page-components/privacy-policy-sections/LegalPageSections.tsx";
import {ClientConsentPageSectionsConfig} from "../../configs/legal-pages/clientConsentSections.ts";
import CustomHelmet from "../../components/helmet/CustomHelmet.tsx";

const ClientConsentPage = () => {
    return (
        <>
            <CustomHelmet title={'titles.client_consent'} canonical={'routes.client_consent'} noIndex={true} />
            <LegalBanner heading={'client_consent'} image={bannerImage} />
            <LegalPageSections config={ClientConsentPageSectionsConfig} />
        </>
    )
}

export default ClientConsentPage;
