import {getCurrentLanguageEnum} from "../../@types/generics.tsx";
import {Section} from "../../@types/privacyPolicySectionResources.tsx";
import {useTranslation} from "react-i18next";
import LegalPageNumericListItem from "./LegalPageNumericListItem.tsx";
import './NumericListStyles.scss';

const LegalPageNumericList = ({section, locale}: {section: Section, locale?: string}) => {

    const {t, i18n} = locale ? useTranslation('translation', {lng: locale}) : useTranslation();

    return (
        <>
            {
                section.languageToShow.includes(getCurrentLanguageEnum(i18n.language))
                    ?
                    <>
                        {section.heading && <li className="fs-6 mb-3 text-blue-dark">{t(section.heading)}</li>}
                        <ol>
                            {section.paragraphs.map((paragraph, index) => (
                                section.languageToShow.includes(getCurrentLanguageEnum(i18n.language))
                                    ? <LegalPageNumericListItem key={index} paragraph={paragraph} locale={locale} />
                                    : null
                            ))}
                        </ol>
                    </>
                    : null
            }
        </>
    )
}

export default LegalPageNumericList;
