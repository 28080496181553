import IsWebsiteRegisteredRadioButtons from "./IsWebsiteRegisteredRadioButtons.tsx";
import {useEffect, useRef, useState} from "react";
import {
    AcceptPaymentsPageFormSectionsNotRegisteredConfig,
} from "../../../configs/accept-payments-page/acceptPaymentsPageFormSectionsNotRegistered.ts";
import FormSection from "../../registration-form/FormSection.tsx";
import {SubmitHandler, useForm} from "react-hook-form";
import {
    AcceptPaymentsPageFormSectionsRegisteredConfig
} from "../../../configs/accept-payments-page/acceptPaymentsPageFormSectionsRegistered.ts";
import MerchantDataSection from "./MerchantDataSection.tsx";
import {AcceptPaymentsInputs} from "../../../@types/acceptPaymentsFormResources.ts";
import ReCAPTCHA from "react-google-recaptcha";
import AgreementSection from "./AgreementSection.tsx";
import {
    clearErrorsOnClick, clearInputs,
    clearPreviousErrors,
    getRegistrationStatus,
    handleRegistrationStatus,
    validateFormFields
} from "../../../helpers/register-form/registerFormHelper.tsx";
import {getFromIso2} from "../../../@types/generics.tsx";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Loading from "../../loading/Loading.tsx";
import {handleEmptyValues} from "../../../helpers/pricing/formHelper.ts";

const AcceptPaymentsForm = () => {

    const { i18n } = useTranslation();

    const captchaRef = useRef<ReCAPTCHA>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const navigate = useNavigate();

    const {register, handleSubmit} = useForm<AcceptPaymentsInputs>();
    const [isWebsiteRegistered, setIsWebsiteRegistered] = useState<boolean>(false);
    const [isLegalEntity, setIsLegalEntity] = useState<boolean>(false);
    const [captcha, setCaptcha] = useState<boolean|null>(null);
    const [loading, setLoading] = useState<boolean>(false)

    const onSubmit: SubmitHandler<AcceptPaymentsInputs> = async (data) => {
        const errors = validateFormFields(formRef.current);

        if (captcha === null) {
            setCaptcha(false);
        }

        if (errors > 0 || captcha === false) {
            clearErrorsOnClick(formRef.current, true);
            return;
        }

        data = handleEmptyValues(data);

        data.language = getFromIso2(i18n.language).toUpperCase();
        data.is_website_registered = isWebsiteRegistered;
        data.is_legal_entity = isLegalEntity;
        data.company_merchant_email && (data.company_merchant_email = data.company_merchant_email.toLowerCase());
        data.installer_email && (data.installer_email = data.installer_email.toLowerCase());
        data.website_email && (data.website_email = data.website_email.toLowerCase());
        data.individual_merchant_email && (data.individual_merchant_email = data.individual_merchant_email.toLowerCase());
        data.merchant_representative_email
        && (data.merchant_representative_email = data.merchant_representative_email.toLowerCase());

        const registrationStatus = await getRegistrationStatus('accept-payments', data, formRef.current, setLoading);

        return handleRegistrationStatus(registrationStatus, formRef.current, captchaRef, navigate, 'accept-payments');
    }

    useEffect(() => {
        clearPreviousErrors();
    }, [isWebsiteRegistered, isLegalEntity]);

    useEffect(() => {
        clearInputs(formRef.current, 'store-section');
    }, [isWebsiteRegistered]);

    useEffect(() => {
        clearInputs(formRef.current, 'merchant-section');
    }, [isLegalEntity]);

    return (
        <>
            <div className="container opy-block">
                <form ref={formRef} id='accept-payments-form' className="start-using-form"
                      onSubmit={handleSubmit(onSubmit)}>
                    <IsWebsiteRegisteredRadioButtons
                        isWebsiteRegistered={isWebsiteRegistered}
                        setIsWebsiteRegistered={setIsWebsiteRegistered}/>
                    <div id='store-section'>
                        {isWebsiteRegistered
                            ? AcceptPaymentsPageFormSectionsRegisteredConfig.map((section, index) => (
                                <FormSection key={index} section={section} register={register}/>
                            ))
                            : AcceptPaymentsPageFormSectionsNotRegisteredConfig.map((section, index) => (
                                <FormSection key={index} section={section} register={register}/>
                            ))
                        }
                    </div>
                    <MerchantDataSection register={register}
                                         isLegalEntity={isLegalEntity}
                                         setIsLegalEntity={setIsLegalEntity}/>
                    <AgreementSection setCaptcha={setCaptcha} captchaRef={captchaRef}/>
                </form>
            </div>
            <a id="verification_popup" style={{display: 'none'}} data-fancybox-type="iframe"></a>
            <Loading display={loading ? 'block' : 'none'} />
        </>
    )
}

export default AcceptPaymentsForm;
